<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.push({ name: 'product'})"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
                    <form @submit.prevent="onFilter" class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <el-input v-model="filter.search" placeholder="Search" clearable @change="onFilter" />
                        </div>
                        <div class="d-flex">
                            <button type="submit" class="btn btn-icon btn-light-primary me-3"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter"><i class="fas fa-times"></i></button>
                        </div>
                    </form>
                    <div class="my-3 fs-6 p-0 text-gray-600 text-uppercase" v-if="filterActive">
                        <span>Hasil filter</span>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_customers_export_modal">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                </svg>
                            </span>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-bordered table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-bottom-2 border-gray-200 text-uppercase">
                            <th>#</th>
                            <th>Gambar</th>
                            <th>Nama</th>
                            <th>Warna</th>
                            <th>Ukuran</th>
                            <th>Artikel</th>
                            <th>Voucher</th>
                            <th class="text-end">HPP (polos+desain)</th>
                            <th class="text-end">Harga Tag</th>
                            <th class="text-end">Harga Retail</th>
                            <th class="text-end">Harga Wholesale</th>
                            <th class="text-end w-175px">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600" v-for="(item, i) in tableData" :key="i">
                        <tr @click="productVariant(item.artikel, i)" :id="'parent_'+i" class="cursor-pointer">
                            <td scope="row">{{ i+1 }}</td>
                            <td><img class="tbl-image" :src="item.tipe_produk == 'MTO' ? img_url_desain+item.gambar : item.tipe_produk == 'RTW' ?img_url_product+item.gambar : img_url_desain+item.gambar" @click.stop.prevent="viewImage(item)"/></td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size }}</td>
                            <td>{{ item.artikel }}</td>
                            <td class="text-danger">{{ item.voucher_name || '-' }}</td>
                            <td class="text-end">{{ item.hpp ? formatIDR(item.hpp, { prefix: 'Rp'}) : 0 }}</td>
                            <td class="text-end">{{ formatIDR(item.price, { prefix: 'Rp'}) }}</td>
                            <td class="text-end">{{ formatIDR(item.discount_price, { prefix: 'Rp'}) }}</td>
                            <td class="text-end ">{{ formatIDR(item.reseller_price, { prefix: 'Rp'}) }}</td>
                            <td class="indicator-arrow text-end w-175px">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-secondary btn-sm me-1" title="Edit semua artikel" @click.stop="getEditAll(item)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button class="btn btn-danger btn-sm me-1" @click.stop="onDeleteAll(item)" v-show="false">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                    <button class="btn btn-primary btn-sm ms-1" title="Show detail">
                                        <i class="ms-2 fas fa-chevron-down view"></i>
                                        <span class="spinner-border spinner-border-sm loadingInside d-none" role="status"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr class="table-dropdown pb-5" :id="'child_'+i">
                            <td colspan="12" style="padding-right: 0 !important;">
                                <table class="table">
                                    <thead>
                                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-bottom-2 border-gray-200 text-uppercase">
                                            <th scope="col">#</th>
                                            <th scope="col">Gambar</th>
                                            <th scope="col">SKU</th>
                                            <th scope="col">Warna</th>
                                            <th scope="col">Ukuran</th>
                                            <th scope="col">Artikel</th>
                                            <th scope="col">Voucher</th>
                                            <th scope="col" class="text-end">Harga Tag</th>
                                            <th scope="col" class="text-end">Harga Retail</th>
                                            <th scope="col" class="text-end">Harga Wholesale</th>
                                            <th scope="col" class="text-end w-175px">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class="fw-bold text-gray-600">
                                        <tr v-for="(det, j) in tableSize" :key="j">
                                            <td><span class="bullet bg-gray-500 w-5px h-5px rounded"></span></td>
                                            <td><img class="cursor-pointer" style="width: 40px; height: 40px; object-fit: cover;" :src="item.tipe_produk.toUpperCase() == 'MTO' ? img_url_desain+det.gambar : item.tipe_produk.toUpperCase() == 'RTW' ? img_url_product+det.gambar : img_url_desain+det.gambar" @click.stop.prevent="viewImage(det)"/></td>
                                            <td scope="col">{{ det.kode }}</td>
                                            <td scope="col">{{ det.color }}</td>
                                            <td scope="col">{{ det.size }}</td>
                                            <td scope="col">{{ det.artikel }}</td>
                                            <td class="text-danger">{{ det.voucher_name || '-' }}</td>
                                            <td scope="col" class="text-end">{{ formatIDR(det.price, { prefix: ''}) }}</td>
                                            <td scope="col" class="text-end">{{ formatIDR(det.discount_price, { prefix: ''}) }}</td>
                                            <td scope="col" class="text-end">{{ formatIDR(det.reseller_price, { prefix: ''}) }}</td>
                                            <td scope="col">
                                                <div class="d-flex justify-content-end">
                                                    <a href="javascript:void(0)" @click="getDetailMto(det)" class="btn btn-white btn-active-light-primary btn-sm me-1" title="Edit per SKU">
                                                        <i class="fas fa-edit"></i>
                                                    </a>
                                                    <a href="javascript:void(0)" @click="onDelete(det)" class="btn btn-white btn-active-light-danger btn-sm ms-1">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>  
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
        </div>

        <el-dialog v-model="modalEdit" :title="editTitle" width="80%" custom-class="dialogMainProductMto" :before-close="handleCancelUpdate">
            <Form
                class="form w-100"
                @submit="onUpdate(updateType)"
            >
                <div class="row">
                    <div class="row d-flex col-12 mb-7">
                        <div class="col-4 px-3">
                            <img v-if="tempData.tipe_produk.toLowerCase() == 'mto'" :src="img_url_desain+tempData.gambar" @click="viewImage({name: 'DESAIN '+tempData.desain_id, gambar: tempData.gambar, })" class="w-100 cursor-pointer" style="max-height: 700px; object-fit: cover;"/>
                            <img v-if="tempData.tipe_produk.toLowerCase() == 'rtw'" :src="img_url_product+tempData.gambar" @click="viewImage({name: 'ARTIKEL '+tempData.artikel, gambar: tempData.gambar, })" class="w-100 cursor-pointer" style="max-height: 700px; object-fit: cover;"/>
                        </div>
                        <div class="col px-3">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">Tipe Produk</label>
                                <input v-bind="field" :value="tempData.tipe_produk.toUpperCase()" class="form-control" disabled />
                            </div>
                            <div class="fv-row mb-7" v-if="tempData.tipe_produk.toLowerCase() == 'mto'">
                                <label class="required fs-6 fw-bold mb-2">Desain ID</label>
                                <input v-bind="field" :value="tempData.desain_id" class="form-control" disabled/>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">Nama (Optional)</label>
                                <input type="text" class="form-control" v-model="tempData.name" placeholder="Masukkan nama (Optional)" />
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">Kategori</label>
                                <Field v-model="tempData.category_id" disabled as="select" class="form-select" data-control="select2" data-placeholder="Pilih produk item" @change="getCategoryPrice($event)" name="category_id">
                                    <option disabled :value="tempData.category_id">{{ tempData.category }}</option>
                                </Field>
                                <div class="fv-plugins-message-container" v-if="!tempData.category_id">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="category_id" />
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">Varian Warna</label>
                                <Field disabled as="select" class="form-select" data-control="select2" data-placeholder="Pilih varian warna" v-model="tempData.artikel" name="artikel">
                                    <option disabled :value="tempData.artikel">{{ tempData.color }} ({{ tempData.artikel + (tempData.vendor ? (' - ' + tempData.vendor ) : '') }})</option>
                                </Field>

                                <div class="fv-plugins-message-container" v-if="!tempData.artikel">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="artikel" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="fv-row mb-7">
                                <el-checkbox
                                    v-model="checkAllSize"
                                    :indeterminate="isIndeterminateSizes"
                                    @change="handlecheckAllSizeChange"
                                    :disabled="listMtoSize.length == 0 || onlyViewInput"
                                >
                                    Ukuran
                                </el-checkbox>
                                <el-checkbox-group
                                    v-if="tempData.size.length > 1 && !isNaN(tempData.size)"
                                    v-model="mtoSize"
                                    @change="handleCheckedSizesChange"
                                >
                                    <el-checkbox v-for="size in listMtoSize" :key="size" :label="size">{{ size.size }}</el-checkbox>
                                </el-checkbox-group>

                                <el-checkbox-group v-else v-model="tempData.checked_size" disabled>
                                    <el-checkbox checked>{{ tempData.size }}</el-checkbox>
                                </el-checkbox-group>
                                
                                <div class="fv-plugins-message-container" v-if="mtoSize.length == 0 ">
                                    <div class="fv-help-block">
                                        <span>Size is required field</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2" title="Harga Pokok Penjualan">HPP (desain+polos)</label>
                                <input type="text" class="form-control" :value="formatIDR(tempData.hpp)" disabled />
                                <div class="fv-plugins-message-container" v-if="buildHpp.desain">
                                    <div class="">
                                        <span v-if="buildHpp.polos > 0">{{ formatIDR(buildHpp.desain) + ' + ' + formatIDR(buildHpp.polos) }}</span>
                                        <span v-else>{{ '(Polos belum dipilih)' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">Harga Tag</label>
                                <Field type="text" class="form-control" placeholder="Atur harga tag" v-model="tempData.price" name="price" :disabled="onlyViewInput" />
                                <div class="fv-plugins-message-container">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="price" />
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ retail_to_percent ? 'Diskon Persen (%)' : 'Harga Diskon (IDR)' }}</label><span class="ms-3" @click="retail_to_percent = !retail_to_percent"><i class="fas fa-exchange-alt"></i></span>
                                <Field type="text" class="form-control" placeholder="Atur harga diskon/retail" v-model="tempData.discount_price" name="discount_price" :disabled="onlyViewInput"/>
                                <div class="fv-plugins-message-container">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="discount_price" />
                                        <span v-if="tempData.artikel && tempData.price && tempData.discount_price" class="text-gray-700 fs-7 fw-bold">MARGIN: {{ formatIDR(calculateMargin(tempData.discount_price, retail_to_percent, 'margin'), { prefix: "Rp" }) + ' ('+ calculateMargin(tempData.discount_price, retail_to_percent, 'margin_percent').toFixed(2) + '%)' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ wholesale_to_percent ? 'Diskon Wholesale (%)' : 'Harga Wholesale (IDR)' }}</label><span class="ms-3" @click="wholesale_to_percent = !wholesale_to_percent"><i class="fas fa-exchange-alt"></i></span>
                                <Field type="text" class="form-control" placeholder="Atur harga wholesale" v-model="tempData.reseller_price" name="reseller_price" :disabled="onlyViewInput"/>
                                <div class="fv-plugins-message-container">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="reseller_price" />
                                        <span v-if="tempData.artikel && tempData.price && tempData.reseller_price" class="text-gray-700 fs-7 fw-bold">MARGIN: {{ formatIDR(calculateMargin(tempData.reseller_price, wholesale_to_percent, 'margin'), { prefix: "Rp" }) + ' ('+ calculateMargin(tempData.reseller_price, wholesale_to_percent, 'margin_percent').toFixed(2) + '%)' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">Voucher (Optional)</label>
                                <Field v-model="tempData.voucher_detail_id" data-control="select2" data-placeholder="Pilih voucher diskon" name="voucher_detail_id">
                                    <el-select 
                                        v-model="tempData.voucher_detail_id" 
                                        class="w-100" 
                                        placeholder="Select"
                                        size="large"
                                        clearable
                                    >
                                        <el-option
                                            :value="''"
                                            :label="'Tidak ada'"
                                            class="custom-list-dropdown"
                                        />
                                        <el-option
                                            v-for="(item, index) in listVoucherDetail" 
                                            :key="index"
                                            :value="item.voucher_detail_id"
                                            :label="item.name + ' ('+ item.kode_voucher +')' + ' - ' + item.store"
                                            class="custom-list-dropdown"
                                        />
                                    </el-select>
                                </Field>
                                <div class="fv-plugins-message-container" v-if="!tempData.voucher_detail_id">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="voucher_detail_id" />
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7 product-price-reference" v-if="Object.keys(categoryPrice).length > 0">
                                <div class="title">
                                    <div>* Referensi harga</div>
                                    <div>
                                        <a class="badge badge-primary" href="/master/price" target="blank">Setting Master</a>
                                    </div>
                                </div>
                                <ul>
                                    <li>
                                        <div class="list-row">
                                            <div>Harga Tag</div>
                                            <div>{{  !categoryPrice['price'] ? 'Belum ada data' : formatIDR(categoryPrice['price'], { prefix: 'Rp'}) }}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-row">
                                            <div>Harga Diskon</div>
                                            <div>{{ !categoryPrice['discount_price'] ? 'Belum ada data' : formatIDR(categoryPrice['discount_price'], { prefix: 'Rp'}) }}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-row">
                                            <div>Harga Wholesale</div>
                                            <div>{{ !categoryPrice['reseller_price'] ? 'Belum ada data' : formatIDR(categoryPrice['reseller_price'], { prefix: 'Rp'}) }}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-5 mb-8">
                        <div class="fv-row">
                            <label class="required fs-6 fw-bold">Gambar Mockup</label>
                            <div class="fv-plugins-message-container my-3" v-if="!tempData.gambar_mto">
                                <div class="fv-help-block">
                                    <span class="text-danger">Gambar mockup is required field</span>
                                </div>
                            </div>
                            <div class="list-mockup mt-5">
                                <div class="mockup row g-2">
                                    <div for="gambar_mto" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" v-for="ob in listMockupDesain" :key="ob" @click="!onlyViewInput && (tempData.gambar_mto = ob.gambar)">
                                        <input type="radio" class="radio-image" name="gambar_mto" v-model="tempData.gambar_mto" :value="ob.gambar" :disabled="onlyViewInput"/>
                                        <div>
                                            <img :src="img_url_desain+ob.gambar"/>
                                            <div class="text-center py-2">
                                                <span>{{ ob.category + ' - ' + ob.color}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 mb-2 text-center" v-if="!onlyViewInput">
                        <button type="button" class="btn btn-light me-3" @click="handleCancelUpdate($event)">Cancel</button>
                        <button
                            type="submit"
                            ref="submitButton"  
                            class="btn btn-primary"
                        >
                            <span class="indicator-label"> Update </span>

                            <span class="indicator-progress">
                                Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                    <div class="mt-4 mb-2 text-center">
                        <small><i>* Jangan lupa cek form input</i></small>
                    </div>
                </div>
            </Form>
        </el-dialog>
        
        <el-dialog v-model="getZoomImage.visibility" :title="getZoomImage.title" width="50%" lock-scroll top="5vh">
            <inner-image-zoom :src="getZoomImage.src"/>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
//import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
    components: { 
        //Datatable,
        Field,
        Form,
        ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_product = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        const loadingTable = ref(false);
        const loadingSubTable = ref(false);
        const tableRef = ref(null)
        
        const tipe_produk = ref('');

        const filterActive = ref(false)
        const filter = reactive({
            search: null,
        })

        const submitButton = ref(null);
        const modalAdd = ref(false)
        const modalEdit = ref(false)
        const editTitle = ref('')
        const updateType = ref('')

        const onlyViewInput = ref(false)

        const retail_to_percent = ref(false)
        const wholesale_to_percent = ref(false)
        
        const tabJenisProduk = ref('mto')

        const tempData = reactive(Yup.object().shape({
            id: '',
            sku: '',
            desain_id: '',
            category: '',
            category_id: Yup.string().required().label("Produk Item"),
            artikel: '',
            color: '',
            color_id: Yup.string().required().label("Varian"),
            hpp: 0,
            price: Yup.string().required().label("Harga Jual"),
            discount_price: Yup.string().required().label("Harga Diskon"),
            reseller_price: Yup.string().required().label("Harga Reseller"),
            voucher_detail_id: '',
            size: '',
            tipe_produk: '',
            tipe_desain: '',
            desain_gambar: '',
            gambar: '',
            name: ''
        }));

        // Custom validation Yup
        // Yup.addMethod(Yup.string, "validDesain", function () {
        //     return this.test(`test-valid-desain`, 'Desain is not valid', function (value) {
        //         const { path, createError } = this;

        //         return (
        //         buildHpp.desain != 0 ||
        //         createError({ path, message: 'Desain is not valid' })
        //         );
        //     });
        // });

        // Yup.addMethod(Yup.string, "validPolos", function () {
        //     return this.test(`test-valid-polos`, 'Polos is not valid', function (value) {
        //         const { path, createError } = this;

        //         return (
        //         buildHpp.polos != 0 ||
        //         createError({ path, message: 'Polos is not valid' })
        //         );
        //     });
        // });

        const tableData = reactive([]);
        const tableSize = reactive([]);
        const totalRecordCount = ref(0);

        const handleCancelUpdate = (done) => {
            ElMessageBox.confirm('Are you sure to cancel update product?')
            .then(() => {
                modalEdit.value = false
            })
            .catch(() => {
                // catch error
            })
        }

        const getDetailMto = async (val) => {
            try {
                editTitle.value = 'Edit Produk per SKU'
                updateType.value = 'sku'

                store.dispatch(Actions.LIST_VOUCHER)

                listCategoryPolos.splice(0)
                listMockupDesain.splice(0)
                listMtoVarian.splice(0)
                listMtoSize.splice(0)
            
                if(val.desain_id) {
                    ApiService.setHeader();
                    ApiService.get("desain_image/"+val.desain_id)
                    .then(({ data }) => {
                        Object.assign(listMockupDesain, data)
                    })
                    .catch(({ response }) => {
                        throw new Error(response.data.messages.error)
                    });
                }

                await ApiService.get("product/"+val.id)
                .then(({ data }) => {
                    tempData.id = data.id
                    tempData.kode = data.kode
                    tempData.artikel = data.artikel
                    tempData.category = data.category
                    tempData.category_id = data.category_id
                    tempData.color = data.color
                    tempData.color_id = data.color_id
                    tempData.size = data.size
                    tempData.size_id = data.size_id
                    tempData.desain_id = data.desain_id
                    tempData.desain_gambar = data.gambar
                    tempData.gambar = data.gambar
                    tempData.gambar_mto = val.gambar
                    tempData.tipe_desain = data.tipe_desain
                    tempData.tipe_produk = data.tipe_produk
                    tempData.name = data.name
                    tempData.hpp = data.hpp
                    tempData.price = data.price
                    tempData.discount_price = data.discount_price
                    tempData.reseller_price = data.reseller_price
                    tempData.checked_size = true
                    tempData.voucher_detail_id = data.voucher_detail_id
                    tempData.voucher_name = data.voucher_name
                    mtoSize.value = [1]
                })
                .catch(({ response }) => {
                    console.log('Error getting list raw polos');
                });

                modalEdit.value = true

            } catch(e) {
                console.error(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const onEditAll = () => {
            console.log('submit edit')
        }

        const getEditAll = async(val) => {
            try {
                editTitle.value = 'Edit Produk per Artikel'
                updateType.value = 'artikel'
                
                console.log(val)

                if(val.desain_id) {
                    ApiService.setHeader();
                    ApiService.get("desain_image/"+val.desain_id)
                    .then(({ data }) => {
                        Object.assign(listMockupDesain, data)
                    })
                    .catch(({ response }) => {
                        throw new Error(response.data.messages.error)
                    });
                }

                tempData.tipe_produk = val.tipe_produk
                tempData.artikel = val.artikel
                tempData.desain_id = val.desain_id
                tempData.desain_gambar = val.gambar
                tempData.gambar = val.gambar
                tempData.gambar_mto = val.gambar
                tempData.tipe_desain = val.tipe_desain
                tempData.tipe_produk = val.tipe_produk
                tempData.name = val.name
                tempData.hpp = val.hpp
                tempData.price = val.price
                tempData.discount_price = val.discount_price
                tempData.reseller_price = val.reseller_price
                tempData.category = val.category
                tempData.category_id = val.category_id
                tempData.color = val.color
                tempData.color_id = val.color_id
                tempData.size = val.size
                tempData.checked_size = true
                tempData.voucher_detail_id = val.voucher_detail_id
                tempData.voucher_name = val.voucher_name
                tempData.size_id = 1
                mtoSize.value = [1]

                modalEdit.value = true

            } catch(e) {
                console.error(e)
            }
        }

        const onDeleteAll = () => {
            try {
                console.log('delete all')

            } catch(e) {
                console.error(e)
            }
        }

        const listRawDesain = reactive([])
        const listCategoryPolos = reactive([])
        const listMockupDesain = reactive([])
        const listMtoVarian = reactive([])        
        const listVoucherDetail = computed(() => {
            return store.getters.getListVoucher
        })

        const itemRef = ref(null)
        const tabRef = ref(null)
        const searchRef = ref(null)
        const totalPage = ref(2)
        const currentPage = ref(1)

        const calculateMargin = (price, percent, type) => {
            let result = 0

            if(percent == true) {
                //result = ((((parseFloat(tempData.price) - ((parseFloat(tempData.price) * parseFloat(price)) / 100)) - parseFloat(tempData.hpp)) / parseFloat(tempData.hpp)) * 100) - 100
                type == 'margin' 
                    ? result = switchToPercent(price, false) - parseFloat(tempData.hpp)
                    : result = ((switchToPercent(price, false) - parseFloat(tempData.hpp)) / tempData.hpp) * 100
            } else {
                type == 'margin' 
                    ? result = (parseFloat(price) - parseFloat(tempData.hpp)) 
                    : result = ((parseFloat(price) - parseFloat(tempData.hpp)) / tempData.hpp) * 100
            }

            return result
        }

        const onFilter = async (val) => {
            await productDetail()
        }

        const resetFilter = async (type) => {
            filter.search = null

            filterActive.value = false
            await productDetail()
        }

        const handlePageChange = async (val) => {
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await productDetail()
        }

        const buildHpp = reactive({
            desain: 0,
            polos: 0
        })

        const getVarian = (e) => {
            let category_id = e.target.value
            let category = e.target.options[e.target.selectedIndex].attributes.category.value
            tempData.category = category

            listMtoVarian.splice(0)
            tempData.color_id = ''
            listMtoSize.splice(0)
            mtoSize.value.splice(0)

            // Reset hpp
            //tempData.price = 0
            tempData.artikel = ''
            tempData.color_id = ''
            buildHpp.polos = 0

            ApiService.setHeader();
            ApiService.get("product/get_variant/"+category_id)
            .then(({ data }) => {
                Object.assign(listMtoVarian, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list varian');
            });
        }

        const getSize = (e) => {
            let artikel = e.target.options[e.target.selectedIndex].attributes.artikel.value
            let color = e.target.options[e.target.selectedIndex].attributes.color.value
            let price = e.target.options[e.target.selectedIndex].attributes.price.value

            console.log(artikel)
            console.log(color) 
            console.log(price)

            tempData.artikel = artikel
            tempData.color = color
            buildHpp.polos = price
            
            listMtoSize.splice(0)
            mtoSize.value.splice(0)
            tempData.artikel = artikel
            ApiService.setHeader();
            ApiService.get("product/get_size/"+artikel+'/'+tempData.desain_id)
            .then(({ data }) => {
                Object.assign(listMtoSize, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list size');
            });
        }

        const checkAllColors = ref(false)
        const isIndeterminateColors = ref(true)
        const checkedColors = ref([
            { id: 4, name: 'black'},
        ])
        const colors = reactive([
            { id: 1, name: 'red'},
            { id: 2, name: 'green'},
            { id: 3, name: 'blue'},
            { id: 4, name: 'black'},
        ])

        const handleCheckAllColorsChange = (val) => {
            checkedColors.value = val ? colors : []
            isIndeterminateColors.value = false
        }
        const handleCheckedColorsChange = (val) => {
            const checkedCountColors = val.length
            checkAllColors.value = checkedCountColors === colors.length
            isIndeterminateColors.value = checkedCountColors > 0 && checkedCountColors < colors.length
        }

        const checkAllSizes = ref(false)
        const isIndeterminateSizes = ref(true)
        const mtoSize = ref([])
        const listMtoSize = reactive([])

        const handleCheckAllSizesChange = (val) => {
            mtoSize.value = val ? listMtoSize : []
            isIndeterminateSizes.value = false
        }
        const handleCheckedSizesChange = (val) => {
            const checkedCountSizes = val.length
            checkAllSizes.value = checkedCountSizes === listMtoSize.length
            isIndeterminateSizes.value = checkedCountSizes > 0 && checkedCountSizes < listMtoSize.length
        }

        // UPLOAD SINGLE FILE
        const file_mto = ref(null)
        const reader = new FileReader();
        const totalSize = ref(0);
        const currentProgress = ref(0);
        function handleEvent(event) {
            if (['loadend', 'load'].includes(event.type)) {
                currentProgress.value = 'Completed 100%';
            }
            if (event.type === 'progress') {
                currentProgress.value = `${(event.loaded / totalSize.value).toFixed(2) * 100}%`;
                //console.log('Loaded: '+event.loaded)
                //console.log('Progress: ', currentProgress.value);
                //console.log('Bytes transferred: ', event.loaded, 'bytes');
            }
            if (event.type === 'loadstart') {
                totalSize.value = event.total;
            }
        }
        function onFileChange(type) {
            var output = document.getElementById('preview-image');

            const fileMto = file_mto.value.files[0];
            tempData.gambar = URL.createObjectURL(fileMto);

            output.onload = function() {
                //URL.revokeObjectURL(output.src) // free memory
            }
        }
        function changeImage(type) {
            tempData.gambar = ''

            setTimeout(() => { 
                document.getElementById('fileMto').value = null
                document.getElementById('fileMto').click()
            }, 100);

        }
        
        const rowIndex = ref(null)
        const rowData = ref(null)
        const rowScrollPosition = ref(null)
        const htmlScrollPosition = ref(null)

        const currentRowView = (index) => {
            tableRef.value.scrollTop = rowScrollPosition.value
            document.querySelector('html').scrollTop = htmlScrollPosition.value
        }

        const onUpdate = async (type) => {
            try {
                const formData = new FormData();
                var createMultiProduk = []
                let validInput = []

                loadingTable.value = true

                // Take all price percent to nominal
                let discount = retail_to_percent.value ? switchToPercent(tempData.discount_price, false) : tempData.discount_price
                let reseller = wholesale_to_percent.value ? switchToPercent(tempData.reseller_price, false) : tempData.reseller_price
                
                let setGambar = null;
                tempData.tipe_produk.toLowerCase() == 'mto' ? setGambar = tempData.gambar_mto : setGambar = tempData.gambar;

                if(validInput) {            
                    if(submitButton.value) {
                        submitButton.value.disabled = true;
                        submitButton.value.setAttribute("data-kt-indicator", "on");
                    }
                }
                
                if(setGambar == null) {
                    throw new Error('Mockup tidak boleh kosong');
                }

                if(type.toLowerCase() == 'sku') {
                   
                    createMultiProduk.push({
                        id: tempData.id,
                        name: tempData.name,
                        category: tempData.category,
                        category_id: tempData.category_id,
                        qrcode: tempData.kode+'-'+tempData.desain_id,
                        tipe: tempData.tipe_desain,
                        tipe_produk: tempData.tipe_produk,
                        kode: tempData.kode,
                        desain_id: tempData.desain_id,
                        artikel: tempData.artikel,
                        color: tempData.color,
                        color_id: tempData.color_id,
                        size: tempData.size,
                        size_id: tempData.size_id,
                        hpp: tempData.hpp,
                        gambar: setGambar,
                        price: tempData.price,
                        discount_price: discount,
                        reseller_price: reseller,
                        voucher_detail_id: tempData.voucher_detail_id,
                    })
                        
                    formData.append('data', JSON.stringify(createMultiProduk))
                    formData.append('artikel', tempData.artikel)
                    
                    await ApiService.post("product/update/"+tempData.id, formData)
                    .then(async({ data }) => {
                        if(data) {
                            Swal.fire({
                                title: data.messages,
                                icon: "success",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000,
                            })
                            
                            await productDetail()
                            await productVariant(rowData.value, rowIndex.value)
                            currentRowView(rowIndex.value)
                            modalEdit.value = false
                        }
                        
                    })
                    .catch(({ response }) => {
                        if(response) {
                            Swal.fire({
                                text: "Failed update product",
                                icon: "error",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    });

                } else if(type.toLowerCase() == 'artikel') {

                    createMultiProduk.push({
                        name: tempData.name,
                        tipe: tempData.tipe_desain,
                        tipe_produk: tempData.tipe_produk,
                        desain_id: tempData.desain_id,
                        artikel: tempData.artikel,
                        gambar: setGambar,
                        price: tempData.price,
                        discount_price: discount,
                        reseller_price: reseller,
                        voucher_detail_id: tempData.voucher_detail_id,
                    })

                    console.log(createMultiProduk)
                        
                    formData.append('data', JSON.stringify(createMultiProduk))

                    let kode = '';

                    if(tempData.tipe_produk.toLowerCase() == 'mto') {
                        kode = tempData.desain_id +'-'+tempData.artikel;
                    } else {
                        kode = tempData.artikel;
                    }

                    await ApiService.post("product/multiple_update/"+kode, formData)
                    .then(async({ data }) => {
                        if(data) {
                            Swal.fire({
                                title: data.messages,
                                icon: "success",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000,
                            })
                            
                            await productDetail()
                            await productVariant(rowData.value, rowIndex.value)
                            currentRowView(rowIndex.value)
                            modalEdit.value = false
                        }
                        
                    })
                    .catch(({ response }) => {
                        if(response) {
                            Swal.fire({
                                text: "Failed update multi product",
                                icon: "error",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    });
                }  
                    
                if(submitButton.value) {
                    submitButton.value.removeAttribute("data-kt-indicator");
                    submitButton.value.disabled = false;
                }

            } catch(e) {
                console.error(e)
            }
            
            loadingTable.value = false
        }

        /*
            END - CREATE PRODUK
        */

        const getZoomImage = reactive({
            title: '',
            src: '',
            visibility: false,
        })

        const viewImage = (data) => {
            getZoomImage.visibility = true
            getZoomImage.title = data.name
            getZoomImage.src = data.tipe_produk == 'MTO' ? img_url_desain.value+data.gambar : data.tipe_produk == 'RTW' ? img_url_product.value+data.gambar : img_url_desain.value+data.gambar;
        }
        
        const onDelete = async (row) => {
            ElMessageBox.confirm(
                'Confirm delete '+row.name+'?',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                    center: false,
                }
            )
            .then(async () => {
                //console.log(row)
                //loadingTable.value = true
                await ApiService.setHeader();
                await ApiService.post("product/delete/"+row.id)
                .then(({ data }) => {
                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });

                    try {
                        tableSize.filter((o, i) => {
                            if(o.id == row.id) {
                                tableSize.splice(i, 1)
                            }
                        })

                        //tableData.map((o, i) => o.index = i); // indexing
                    } catch {
                        window.location.reload();
                    }
                    
                    //removeObjectArray(tableData, item => item.id !== row.id); // Need reindexing (old method) 
                })
                .catch(({ response }) => {
                    if(response) {
                        Swal.fire({
                            title: response.error,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                });

                //console.log(tableSize)

                if(tableSize.length == 0) {
                    tableData.splice(0)
                }
            })
            .catch(() => {
                //
            })
            
        }

        const removeObjectArray = (array, predicate) => {
            let end = 0;

            for (let i = 0; i < array.length; i++) {
                const obj = array[i];

                if (predicate(obj)) {
                    array[end++] = obj;
                }
            }

            array.length = end;
        };

        const row_click = ref(false)

        const productVariant = async (artikel, index) => {
            try {
                let kode = route.params.kode
                
                tableSize.splice(0)
                loadingSubTable.value = true
                
                // Current edit row
                rowIndex.value = index
                rowData.value = artikel

                document.getElementById('parent_'+index).querySelector('.loadingInside').classList.remove('d-none');
                document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.add('d-none');

                document.getElementById('parent_'+index).closest('#parent_'+index).classList.toggle('table-active');
                document.getElementById('child_'+index).classList.toggle('table-active');

                // Fetch data
                if(document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.contains('fa-chevron-down')) {
                    await ApiService.post("product_detail/"+kode+'/'+artikel, { tipe_produk: tipe_produk.value })
                    .then(({ data }) => {
                        if(data.error) {
                            throw new Error('Error getting product detail')
                        }

                        totalRecordCount.value = data.length
                        Object.assign(tableSize, data)
                    })
                    .catch(({ response }) => {
                        throw new Error(response.data.messages.error)
                    });
                }

                document.getElementById('child_'+index).classList.toggle('table-dropdown-show');

                if(document.getElementById('child_'+index).classList.contains('table-dropdown-show')) {
                    document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.add('fa-chevron-up');
                    document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.remove('fa-chevron-down');
                } else {
                    document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.add('fa-chevron-down');
                    document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.remove('fa-chevron-up');
                }

                tableData.filter((o, i) => {
                    if(i != index) {
                        document.getElementById('child_'+i).classList.remove('table-dropdown-show');
                        document.getElementById('parent_'+i).closest('#parent_'+i).classList.remove('table-active');
                        document.querySelector('#parent_'+i+' > .indicator-arrow i.view').classList.remove('fa-chevron-up');
                        document.querySelector('#parent_'+i+' > .indicator-arrow i.view').classList.add('fa-chevron-down');
                    }
                })

            } catch(e) {
                Swal.fire({
                    text: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loadingSubTable.value = false
            document.getElementById('parent_'+index).querySelector('.loadingInside').classList.add('d-none');
            document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.remove('d-none');
        }

        const productDetail = async () => {
            tableData.splice(0)

            loadingTable.value = true
            await ApiService.setHeader();
            await ApiService.post("product_detail/" + route.params.kode, { tipe_produk: route.params.tipe_produk, search: filter.search })
            .then(({ data }) => {
                tableData.splice(0)
                
                totalRecordCount.value = data.length
                Object.assign(tableData, data)

                tipe_produk.value = data[0].tipe_produk;

                let titlePage = ''
                if(data[0].tipe_produk == 'MTO')
                    titlePage = data[0].category+' - DESAIN '+ data[0].desain_id + ' (MTO)'
                if(data[0].tipe_produk == 'RTW')
                    titlePage = data[0].category+' - '+ data[0].artikel + ' (RTW)'
                if(data[0].tipe_produk == 'DISPLAY')
                    titlePage = data[0].category+' - '+ data[0].artikel + ' (DISPLAY)'

                setCurrentPageBreadcrumbs(titlePage, ["Product Jadi", titlePage]);
            })
            .catch(({ response }) => {
                console.log('Error getting produk main');
            });
            
            loadingTable.value = false
        }

        const categoryPrice = reactive({})
        
        const getCategoryPrice = async (val) => {
            let category_id = val;

            try {
                Object.keys(categoryPrice).forEach(key => delete categoryPrice[key]);

                await ApiService.setHeader();
                await ApiService.get("price/category/"+category_id)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Object.assign(categoryPrice, data.data)

                    if(!Object.keys(categoryPrice).some(k => !categoryPrice[k] )) {
                        tempData.price = categoryPrice.price
                        tempData.discount_price = categoryPrice.discount_price
                        tempData.reseller_price = categoryPrice.reseller_price

                    } else {
                        tempData.price = null
                        tempData.discount_price = null
                        tempData.reseller_price = null
                    }
                })
                .catch((response) => {
                    console.log('Error getting detail category price');
                    throw new Error(response)
                });
            } catch(e) {
                console.log(e.message)
            }
        }


        onMounted( async() => {
            await productDetail();
            store.dispatch(Actions.LIST_VOUCHER)
        })

        watchEffect(() => {
            // Set height of scroll item
            if(itemRef.value && searchRef.value && tabRef.value) {
                let elDialogBody = document.querySelector(".dialogMainProduct > .el-dialog__body")
                let elDialogFooter = document.querySelector(".dialogMainProduct > .el-dialog__footer")

                //elDialogBody.style.height = window.innerHeight - (elDialogBody.offsetHeight - elDialogFooter.offsetHeight - searchRef.value.offsetHeight) + 'px'
                //itemRef.value.style.height = window.innerHeight - (itemRef.value.offsetHeight - searchRef.value.offsetHeight - tabRef.value.offsetHeight - 15) + 'px'

                //console.log(itemRef.value.offsetHeight + ', '+  searchRef.value.offsetHeight + ', '+ tabRef.value.offsetHeight + ', ' + window.innerHeight)
            }   
        })

        watch(modalEdit, (val) => {
            if(!val) {
                // Reset all value input
                tempData.category_id = ''
                tempData.desain_gambar = ''
                tempData.color_id = ''
                tempData.artikel = ''
                tempData.size_id = ''
                buildHpp.desain = 0
                buildHpp.polos = 0

                listCategoryPolos.splice(0)
                listMockupDesain.splice(0)
                listMtoVarian.splice(0)
                listMtoSize.splice(0)
            }
        })  

        const switchToPercent = (price, percent) => {
            let result = 0
            try {
                if(percent == true) {
                    result = (100 - ((parseFloat(price) / parseFloat(tempData.price)) * 100)) // to percent
                } else {
                    result = Math.ceil((parseFloat(tempData.price) - ((parseFloat(price) / 100) * parseFloat(tempData.price)))) // to idr
                }
            } catch {
                console.error('Error converting data')
            }

            return result
        }

        watch(retail_to_percent, (val) => {
            if(tempData.price) {
                tempData.discount_price = switchToPercent(parseFloat(tempData.discount_price), val)
            }
        })

        watch(wholesale_to_percent, (val) => {
            if(tempData.price) {
                tempData.reseller_price = switchToPercent(parseFloat(tempData.reseller_price), val)
            }
        })


        return {
            loadingTable, loadingSubTable, tableRef, img_url_mockup, img_url_product, img_url_desain, tabJenisProduk, itemRef, tabRef, searchRef, tipe_produk,
            tableData, totalRecordCount, retail_to_percent, wholesale_to_percent,
            tempData, listRawDesain, listCategoryPolos, listMockupDesain, listMtoVarian, listMtoSize, getDetailMto, getVarian, getSize, buildHpp, mtoSize,
            listVoucherDetail,
            modalEdit, onlyViewInput, editTitle, updateType, handleCancelUpdate,
            onFilter, resetFilter, filter, handlePageChange,
            file_mto, onFileChange, changeImage, viewImage, getZoomImage,
            onUpdate, onDelete, submitButton, onEditAll, onDeleteAll, getEditAll,
            formatIDR, calculateMargin,
            isIndeterminateColors, colors, checkedColors, checkAllColors, handleCheckAllColorsChange, handleCheckedColorsChange,
            isIndeterminateSizes, checkAllSizes, handleCheckAllSizesChange, handleCheckedSizesChange, totalPage, currentPage,
            InfoFilled,
            categoryPrice, getCategoryPrice,
            route, router,
            tableSize, productVariant, row_click
        }
    }
})
</script>